import { Routes } from '@angular/router';
import { authedGuard, notLoggedInGuard, welcomeGuard } from './core/guards/authed.guard';
import { redirectToGuard, redirectToStorageGuard } from './core/guards/redirect-to-storage.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    canActivate: [notLoggedInGuard, redirectToStorageGuard],
    loadComponent: () => import('./auth/auth.component'),
  },
  {
    path: 'auth/:platform',
    loadComponent: () => import('./auth/callback/callback.component'),
  },
  {
    path: 'unsubscribed/:accountId',
    loadComponent: () => import('./unsubscribed/unsubscribed.component'),
  },
  {
    path: 'verify-email/:token',
    loadComponent: () => import('./verify-email/verify-email.component'),
  },
  {
    path: 'payment-success',
    loadComponent: () => import('./payment-success/payment-success.component'),
  },
  {
    path: 'payment-failure',
    loadComponent: () => import('./payment-failure/payment-failure.component'),
  },
  {
    path: 'claim-machine/:token',
    loadComponent: () => import('./claim-machine/claim-machine.component'),
  },
  {
    path: 'app',
    canActivate: [redirectToGuard, authedGuard],
    loadComponent: () => import('./layout/layout.component'),
    children: [
      {
        path: '',
        canActivate: [welcomeGuard],
        children: [
          {
            path: 'getting-started',
            loadComponent: () => import('./getting-started/getting-started.component'),
          },
          {
            path: '',
            children: [
              {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
              },
              {
                path: 'dashboard',
                loadComponent: () => import('./dashboard/dashboard.component'),
              },
              {
                path: 'machines',
                loadComponent: () => import('./machine-dashboard/machine-dashboard.component'),
              },
              {
                path: 'machines/:machineId',
                loadComponent: () =>
                  import('./machine-backup-grouped-dashboard/machine-backup-grouped-dashboard.component'),
              },
              {
                path: 'machines/:machineId/backup/:backupId',
                loadComponent: () => import('./machine-backup-dashboard/machine-backup-dashboard.component'),
              },
              {
                path: 'backups',
                loadComponent: () => import('./backup-dashboard/backup-dashboard.component'),
              },
            ],
          },
          {
            path: 'settings',
            loadChildren: () => import('./settings/settings.routes'),
          },
          {
            path: 'admin-panel',
            loadChildren: () => import('./admin-panel/admin-panel.routes'),
          },
        ],
      },
    ],
  },
];
