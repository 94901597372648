import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { RedirectToState } from '../states/redirect-to.state';

export const redirectToStorageGuard: CanActivateFn = (route) => {
  const state = inject(RedirectToState);

  const params = route.queryParams;

  if (params['redirectTo']) {
    state.setRedirectTo(params['redirectTo']);
  }

  return true;
};

export const redirectToGuard: CanActivateFn = () => {
  const state = inject(RedirectToState);
  const router = inject(Router);

  const redirectTo = typeof state.redirectTo() === 'string' ? state.redirectTo() + '' : null;

  if (redirectTo) {
    state.clearRedirectTo();
    router.navigate([redirectTo]);
  }

  return true;
};
