import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SparkleAlertComponent } from '@sparkle-ui/core';
import { Observable, combineLatestWith, finalize, tap } from 'rxjs';
import { AuthState } from 'src/app/auth/auth.state';
import { AccountService, LegalService, Me } from '../../openapi';

const fb = new FormBuilder();

@Component({
  selector: 'app-welcome-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatCheckboxModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    SparkleAlertComponent,
  ],
  templateUrl: './welcome-dialog.component.html',
  styleUrl: './welcome-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeDialogComponent {
  #authState = inject(AuthState);
  #legalService = inject(LegalService);
  #accountService = inject(AccountService);
  #dialogRef = inject(MatDialogRef<WelcomeDialogComponent>);

  isLoading = signal(false);

  form = fb.group({
    name: fb.control(''),
    lastName: fb.control(''),
    email: fb.control(''),
    tosAndPolicy: fb.control(false, [Validators.required, Validators.requiredTrue]),
    marketingConsent: fb.control(false),
  });

  ngOnInit() {
    this.setFormValues();

    this.form.controls.email.disable();
  }

  setFormValues() {
    const user = this.#authState.$activeUser();
    const firstPrivacyPolicy = user?.contractApprovals?.find((val) => val.contractType === 'PrivacyPolicy');
    const firstToS = user?.contractApprovals?.find((val) => val.contractType === 'TermsOfService');

    const userTosDate = new Date(firstToS?.accept as string).getTime();
    const userPrivacyPolicyDate = new Date(firstPrivacyPolicy?.accept as string).getTime();

    if (!user) {
      (
        this.#authState.fetchUser({
          returnSubscription: true,
        }) as Observable<Me>
      )
        ?.pipe(
          tap((_) => {
            this.setFormValues();
          })
        )
        .subscribe();
      return;
    }

    this.#legalService
      .getLegalcontractsTermsofservice()
      .pipe(combineLatestWith(this.#legalService.getLegalcontractsPrivacypolicy()))
      .subscribe(([terms, privacyPolicy]) => {
        const termsPublished = new Date(terms.published as string).getTime();
        const privacyPolicyPublished = new Date(privacyPolicy.published as string).getTime();

        const bothApproved = termsPublished > userTosDate && privacyPolicyPublished < userPrivacyPolicyDate;
        this.form.patchValue({
          name: user?.name ?? '',
          lastName: user?.lastName ?? '',
          email: user?.email ?? '',
          marketingConsent: user?.marketingConsent,
          tosAndPolicy: bothApproved,
        });
      });
  }

  submit() {
    if (this.form.valid) {
      const { name, lastName, email, tosAndPolicy, marketingConsent } = this.form.value;

      this.isLoading.set(true);

      this.#accountService
        .patchAccountsMe({
          requestBody: {
            firstName: name,
            lastName,
            marketingConsent: !!marketingConsent,
            acceptTerms: tosAndPolicy,
          },
        })
        .pipe(finalize(() => this.isLoading.set(false)))
        .subscribe(() => {
          this.#authState.fetchUser();
          this.#dialogRef.close();
        });
    }
  }
}
