import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { SparkleAlertService } from '@sparkle-ui/core';
import { catchError, switchMap, throwError } from 'rxjs';
import { AuthState } from 'src/app/auth/auth.state';
import { ENVIRONMENT_TOKEN } from 'src/environments/environment-token';

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthState);
  const env = inject(ENVIRONMENT_TOKEN);
  const sparkleAlertService = inject(SparkleAlertService);

  let _req = req;

  if (auth.$token() && req.url.startsWith(env.baseUrl)) {
    _req = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${auth.$token()}`),
    });
  }

  return next(_req).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMsg = '';

      if (error.status === 401) {
        return auth.refreshToken().pipe(
          switchMap(() => {
            return next(
              req.clone({
                headers: req.headers.set('Authorization', `Bearer ${auth.$token()}`),
              })
            ).pipe(
              catchError((err) => {
                console.log(err);

                auth.logout();
                return throwError(() => 'Error: Refresh token failed');
              })
            );
          })
        );
      } else {
        sparkleAlertService.error(error.message);
      }

      errorMsg = `Error Code: ${error.status}, Message: ${error.message}`;
      return throwError(() => errorMsg);
    })
  );
};
