<h3 class="title-20" mat-dialog-title>Welcome to duplicati</h3>

<mat-dialog-content>
  <form [formGroup]="form" (submit)="submit()">
    <div class="row">
      <mat-form-field>
        <mat-label>First name</mat-label>
        <input matInput type="text" name="name" formControlName="name" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Last name</mat-label>
        <input matInput type="text" name="lastName" formControlName="lastName" />
      </mat-form-field>
    </div>

    <mat-form-field [attr.readonly]="true">
      <mat-label>E-mail</mat-label>
      <input matInput type="email" name="email" formControlName="email" />
    </mat-form-field>

    <div class="approvals">
      <mat-checkbox formControlName="tosAndPolicy" color="primary">
        I agree to the
        <a href="https://duplicati.com/termsofservice" target="_blank">Terms of Service</a>
        and
        <a href="https://duplicati.com/privacypolicy" target="_blank">Privacy Policy</a>
      </mat-checkbox>

      <mat-checkbox formControlName="marketingConsent" color="primary">
        I agree to receive marketing emails
      </mat-checkbox>
    </div>

    @if (!form.value.tosAndPolicy) {
      <sparkle-alert type="question">
        Make sure to accept terms of service and privacy policy before you continue.
      </sparkle-alert>
    }

    <button
      mat-raised-button
      color="primary"
      type="submit"
      matTooltip="Not implemented yet, coming soon"
      [disabled]="!form.valid || isLoading()">
      @if (isLoading()) {
        Saving...
      } @else {
        Save
      }
    </button>
  </form>
</mat-dialog-content>
