export const environment = {
  production: false,
  baseUrl: 'https://dev-api.duplicati.com',
  ingressUrl: 'https://dev-ingress.duplicati.com/backupreports',

  github: {
    authUrl: 'https://github.com/login/oauth/authorize',
    clientId: 'Iv1.9f811a64754ad195',
    redirectPath: '/auth/github',
    scopeArr: ['read:user', 'user:email'],
    allowSignup: 'true',
  },
  google: {
    authUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
    clientId: '241155532614-qii3ri2mc7s2ofq5i1dol6912q6j0gqb.apps.googleusercontent.com',
    redirectPath: '/auth/google',
    scopeArr: ['https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/userinfo.profile'],
    responseType: 'code',
    accessType: 'offline',
    prompt: 'consent',
  },
};
