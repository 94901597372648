import { inject, Injectable, signal } from '@angular/core';
import { LOCALSTORAGE } from '../services/localstorage.token';

@Injectable({
  providedIn: 'root',
})
export class RedirectToState {
  #ls = inject(LOCALSTORAGE);

  redirectTo = signal<string | null>(this.#ls.getItemParsed<string | null>('redirectTo', true) ?? null);

  setRedirectTo(redirectTo: string | null) {
    this.redirectTo.set(redirectTo);

    if (!redirectTo) {
      return;
    }

    this.#ls.setItemParsed('redirectTo', redirectTo, true);
  }

  clearRedirectTo() {
    console.log('hi: ');

    this.redirectTo.set(null);
    this.#ls.removeItem('redirectTo', true);
  }
}
